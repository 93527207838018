<script lang="ts">
    import { onMount } from 'svelte';

    export let color = 'black';

    let p = 0;
    let visible = false;

    onMount(() => {
        visible = true;
        function next() {
            p += 0.1;
            const remaining = 1 - p;
            if (remaining > 0.15) setTimeout(next, 500 / remaining);
        }
        setTimeout(next, 250);
    });
</script>

{#if visible}
    <div class="progress-container">
        <div class="progress" style={`background: ${color}; width: ${p * 100}%`} />
    </div>
{/if}

<style>
    .progress-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        z-index: 999;
    }
    .progress {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        transition: width 0.4s;
    }
</style>
